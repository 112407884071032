import axiosInstance from "../api/axiosInstance";

export async function getAllPages(isAuthenticated: boolean) {
	const section = isAuthenticated
		? ["Rooster Support", "Restricted"]
		: ["Rooster Support"];
	const response = await axiosInstance.post("/api/pages", { section });
	return response.data;
}

export async function queryData(isAuthenticated: boolean, query: string) {
	const response = await axiosInstance.post("/api/search", {
		query,
		isAuthenticated,
	});
	return response.data;
}

export async function getContent(isAuthenticated: boolean, pageId: string) {
	try {
		const response = await axiosInstance.post("/api/get-page", {
			pageId,
			isAuthenticated,
		});

		const body = response.data.body;
		const title = response.data.title;
		return { body, title };
	} catch (error) {
		// console.error("Error fetching page content:", error);
		return { body: "Error", title: "Unable to load content" };
	}
}

// Function to handle file download
export const downloadFile = async (downloadUrl: string, fileName: string) => {
	try {
		const response = await axiosInstance.post(
			"/api/download",
			{ downloadUrl },
			{
				responseType: "blob",
			},
		);
		let fileUrl = new URL("http://dummy.com");
		let param = "";
		let filename = fileName;
		// if downloadUrl starts with https://roosterinc.atlassian.net
		if (downloadUrl.startsWith("https://roosterinc.atlassian.net")) {
			fileUrl = new URL(downloadUrl);
			if (!filename) {
				// if the file url has preview as params then use that as the file name
				if (fileUrl.searchParams.has("preview")) {
					param = fileUrl.searchParams.get("preview") || "downloaded-file";
				} else {
					// set param to the file name
					param = fileUrl.pathname.split("/").pop() || "downloaded-file";
				}
				const decodedPreview = decodeURIComponent(param);
				filename = decodedPreview;
			}
		}
		// if downloadUrl starts with /wiki
		else {
			if (!filename) {
				fileUrl = new URL(`http://dummy.com${downloadUrl}`);
				param = fileUrl.searchParams.get("preview") || "downloadfile";
				const decodedPreview = decodeURIComponent(param);
				filename = decodedPreview.split("/").pop() || "downloaded-file";
			}
		}

		const blob = new Blob([response.data], { type: response.data.type });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error downloading file");
	}
};

// Function to handle PDF download
export const downloadPDF = async (pageTitle: string, contentHtml: string) => {
	try {
		const response = await axiosInstance.post(
			"/api/getPDF",
			{ pageTitle, pageContent: contentHtml },
			{
				responseType: "blob",
			},
		);

		const filename = `${pageTitle}.pdf`;

		const blob = new Blob([response.data], { type: "application/pdf" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	} catch (error) {
		console.error("Error downloading PDF:");
	}
};
