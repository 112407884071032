export const processContent = (
	content: string,
	setSelectedPageId: (pageId: string) => void,
) => {
	const processedImages = processImages(content);
	const processedDownloadLinks = processDownloadLinks(
		processedImages,
		setSelectedPageId,
	);
	const processedPureLinks = processPureLinks(processedDownloadLinks);
	const formattedBody = processHeaders(processedPureLinks);
	return formattedBody;
};

export const processDownloadLinks = (
	content: string,
	setSelectedPageId: (pageId: string) => void,
) => {
	const container = document.createElement("div");
	container.innerHTML = content;

	const anchors = container.querySelectorAll<HTMLAnchorElement>(
		"a[data-linked-resource-id]",
	);
	for (const anchor of Array.from(anchors)) {
		anchor.removeAttribute("href");
		anchor.style.cursor = "pointer";
		const pageId = anchor.getAttribute("data-linked-resource-id");
		anchor.addEventListener("click", (e: Event) => {
			e.preventDefault();
			if (pageId) {
				setSelectedPageId(pageId);
			}
		});
		anchor.setAttribute("has-click-listener", "true");
	}

	const formattedText = container.innerHTML;
	container.remove();
	return formattedText;
};

export const processPureLinks = (content: string) => {
	const container = document.createElement("div");
	container.innerHTML = content;

	const anchors = container.querySelectorAll<HTMLAnchorElement>("a");

	for (const anchor of Array.from(anchors)) {
		const href = anchor.getAttribute("href");
		if (href?.includes("https://roosterinc.atlassian.net")) {
			anchor.setAttribute("data-file-url", href);
			anchor.removeAttribute("href");
			anchor.style.cursor = "pointer";
		}
	}

	const formattedText = container.innerHTML;
	container.remove();
	return formattedText;
};

export const processImages = (content: string) => {
	const container = document.createElement("div");
	container.innerHTML = content;
	const imageTags = container.querySelectorAll<HTMLImageElement>(
		"img.confluence-embedded-image",
	);

	for (const imgTag of Array.from(imageTags)) {
		const imgSrc = imgTag.getAttribute("src");
		if (imgSrc) {
			imgTag.src = `${
				process.env.REACT_APP_API_BASE_URL
			}/api/getImage?imageUrl=${encodeURIComponent(imgSrc)}`;
		}
	}

	container.remove();
	const formattedText = container.innerHTML;
	return formattedText;
};

export const processHeaders = (content: string) => {
	const container = document.createElement("div");
	container.innerHTML = content;
	const headers = container.querySelectorAll<HTMLHeadingElement>(
		"h1, h2, h3, h4, h5, h6",
	);
	if (headers) {
		headers.forEach((header, index) => {
			const id = `header-${index}`;
			header.setAttribute("id", id);
		});
	}
	container.remove();
	const formattedText = container.innerHTML;
	return formattedText;
};

type Header = {
	text: string;
	level: number;
	id: string;
};

export const getHeaderList = (content: string) => {
	const headerList: Header[] = [];
	const container = document.createElement("div");
	container.innerHTML = content;
	const headers = container.querySelectorAll<HTMLHeadingElement>(
		"h1, h2, h3, h4, h5, h6",
	);
	if (headers) {
		for (const header of Array.from(headers)) {
			const level = Number.parseInt(header.tagName[1]);
			const id = header.getAttribute("id") || "";
			// if id matches "header-" then extract the em tag under it and use it as text
			if (id.includes("header-")) {
				const em = header.querySelector("em");
				if (em) {
					header.textContent = em.textContent || "";
				}
			}

			console.log(header);
			headerList.push({
				text: header.textContent || "",
				level: level,
				id,
			});
		}
	}
	container.remove();
	return headerList;
};
